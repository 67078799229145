
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'Sunkare',
  components: {
  },
  
  data: function(){
    return{
      Open:false,
      Mute:true,
      Start:false
    }
  },

  methods: {
    JumpURL(){
      const url = 'https://sabukare.com/app/page_login_mail_pass.html?rkey=gplay2307'
      window.location.href = url
    },

    JumpLogin(){
      const Loginurl = 'https://sabukare.com/app/page_login_mail_pass.html?rkey=gplay2307'
      window.location.href = Loginurl
    },

    VolChange(){
      const videoTag = document.querySelector("video");
      if(videoTag!.muted === true){
        videoTag!.muted = false;
        this.Mute = false;
      } else {
        videoTag!.muted = true;
        this.Mute = true;
      }
    }
  },

  mounted(){
    setTimeout(() => {
      this.Open = true;
    }, 3);

    setTimeout(() => {
      this.Start = true;
    }, 200);

    const setFillHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight);

    // 初期化
    setFillHeight();
  }
});
