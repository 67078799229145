
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'sample1',
  components: {
  },
  
  data: function(){
    return{
    }
  },

  methods: {
    Close: function(){
      this.$emit("onClick", false);
    }
  },

  
  mounted(){
  }
});
