import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Subkare from '../views/Subkare.vue'
import push from '../views/push.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Subkare',
    component:Subkare
  },
  {
    path: '/725TYMPf8bjLojkFP4OI/push',
    name: 'Push',
    component:push
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
